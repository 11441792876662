<template>
  <div>
    <!-- 认购 -->
    <div class="mintage">
      <div class="header">
        <div class="row">
          <img src="@/assets/img/logo.png" class="header-icon" />
          <div class="title">{{ $t('立即') }}{{ $t(buyInfo) }}</div>
        </div>
      </div>

      <div class="select" v-if="touziSetting">
        <div class="select-list">
          <div :class="['select-list-item', active == index ? 'active' : '']"
            v-for="(item, index) in touziSetting.amounts" :key="index" @click="onClick(index)">
            <img style="width: 45%;" :src="require(`@/assets/img/touzi/p${index + 1}.png`)">
            <div style="margin-top: 30px;text-align: center;">
              <div style="font-size: 18px;font-weight: bold;">${{ item }}</div>
              <div style="font-size: 12px;color: #888;">{{ $t('出局倍数') }}2{{ $t('倍') }}</div>
              <div style="font-size: 12px;color: #888;">{{ $t('释放比例') }}{{ touziSetting.shouyiBilis[index] }}%</div>
            </div>
          </div>
        </div>
      </div>

      <div class="form">
        <div class="input-wrap" v-if="zflx != 3">
          <div class="input-label">{{ $t('数量') }}</div>
          <div class="input-row">
            <img src="@/assets/img/base/jian.png" class="stepper-icon" @click="reduce" />
            <div class="input">
              <q-input borderless v-model="jine" dense placeholder="请输入" type="number"
                :input-style="{ textAlign: 'center', color: '#fff' }" />
            </div>
            <img src="@/assets/img/base/jia.png" class="stepper-icon" @click="plus" />
          </div>
        </div>
        <div class="consume">
          <van-radio-group v-model="zflx" direction="horizontal" icon-size="16px">
            <van-radio name="1" checked-color="#e56efa">
              <div class="row items-center">
                <div class="consume-title">USDT {{ $t('认购') }}</div>
              </div>
            </van-radio>
            <van-radio name="2" checked-color="#e56efa">
              <div class="row items-center">
                <div class="consume-title">BNB {{ $t('认购') }}</div>
              </div>
            </van-radio>
          </van-radio-group>
          <div class="link" @click="tolink('TouziRecord')">{{ $t('认购记录') }}</div>
        </div>

        <div style="margin-top: 15px;" v-if="zflx == 1">
          <div class="cell">
            <div>{{ $t('可用') }}USDT：{{ Number(balance.usdt).toFixed(6) }}</div>
            <div class="mintage-text" @click="jine = balance.usdt">{{ $t('最大') }}</div>
          </div>
        </div>
        <div style="margin-top: 15px;" v-if="zflx == 2">
          <div class="cell">
            <div>{{ $t('支付') }}BNB</div>
            <div>{{ (bnbPrice * jine).toFixed(6) }}</div>
          </div>
          <div class="cell" style="margin-top: 10px;">
            <div>{{ $t('可用') }}BNB：</div>
            <div>{{ balance.bnb }}</div>
          </div>
        </div>
      </div>

      <div v-if="zflx == 1">
        <div class="btn" v-if="userAllowance.usdt < parseFloat(jine)" @click="approve(1)">{{ $t('授权金额') }}</div>
        <div class="btn" v-if="userAllowance.usdt >= parseFloat(jine)" @click="touzi">USDT {{ $t(buyInfo) }}</div>
      </div>
      <div class="btn" v-if="zflx == 2" @click="touzi2">BNB {{ $t(buyInfo) }}</div>

      <div class="article" v-if="articleData">
        <div v-html="articleData"></div>
      </div>
    </div>


    <!-- 充值 -->
    <div class="mintage">
      <div class="header">
        <div class="row">
          <img src="@/assets/img/logo.png" class="header-icon" />
          <div class="title">LBD{{ $t('充值') }}</div>
        </div>
        <div class="link" @click="tolink('ChongzhiRecord')">{{ $t('充值记录') }}</div>
      </div>
      <div class="input-wrap">
        <q-input borderless v-model="num" dense :input-style="{ color: '#fff' }" style="width: 100%;"
          :placeholder="$t('请输入数量')" />
      </div>
      <div class="cell text-white" style="margin-top: 15px;">
        <div>{{ $t('可用') }}LBD：{{ balance.lbd }}</div>
      </div>
      <div class="row">
        <div class="btn col q-mx-xs" v-if="userAllowance.lbd < parseFloat(num) || num==''" @click="approve(2)">{{ $t('授权金额') }}</div>
        <div class="btn col q-mx-xs" v-if="userAllowance.lbd >= parseFloat(num)" @click="chongzhi">{{ $t('充值') }}</div>
      </div>
    </div>

    <!-- <div class="mintage">
      <div class="header">
        <div class="row">
          <img src="@/assets/img/logo.png" class="header-icon" />
          <div class="title">LP映射</div>
        </div>
        <div class="link" @click="tolink('ChongzhiRecord?lx=4&title=映射记录')">{{ $t('映射记录') }}</div>
      </div>
      <div class="input-wrap">
        <q-input borderless v-model="num2" dense :input-style="{ color: '#fff' }" style="width: 100%;"
          :placeholder="$t('请输入数量')" />
      </div>
      <div class="cell text-white" style="margin-top: 15px;">
        <div>{{ $t('可用') }}Lp：{{ balance.lp }}</div>
        <div class="mintage-text" @click="num2 = balance.lp">{{ $t('全部') }}</div>
      </div>
      <div class="row">
        <div class="btn col q-mx-xs" @click="chongzhi2">映射</div>
      </div>
    </div> -->


    <!-- txid -->
    <q-dialog v-model="showTxid" persistent>
      <q-card class="q-dialog-plugin" style="min-width: 350px">
        <q-card-section>
          <div class="text-body1">{{ $t('交易广播成功') }}</div>
          <div class="text-body2">Txid</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <div class="input-wrap">
            <q-input borderless v-model="txid" dense :input-style="{ color: '#fff' }" style="width: 100%;" readonly>
              <template v-slot:append>
                <q-icon name="content_copy" style="font-size: 16px;" color="white" @click="copy(txid)" />
              </template>
            </q-input>
          </div>
        </q-card-section>
        <div style="padding: 0 16px 16px;">
          <div class="btn" style="margin: 0;" @click="showTxid = false">{{ $t('确定') }}</div>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref } from 'vue';
import Web3Helper from "@/assets/js/web3helper.js";
import CustomComponent from "@/components/CustomComponent";
import useClipboard from 'vue-clipboard3';
import { RadioGroup, Radio } from 'vant';

import ExtractChineseWords from "@/assets/js/language/tool/extract-chinese-words.js";
export default {
  name: 'Touzi',
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  setup() {
    return {
      jine: ref(0),
      us: ref(null),
      touziSetting: null,
      active: ref(0),
      articleData: ref(""),
      buyInfo: "认购",

      contractInfoBNB: ref(""),
      contractInfoUSDT: ref(null),
      contractInfoLBD: ref(null),
      contractInfoLP: ref(null),
      contractInfoApproveContract: ref(null),
      contractInfoPayContract: ref(null),
      contractInfoSwapRouterV3: ref(null),

      usdtWeb3Helper: ref({}),
      lbdWeb3Helper: ref({}),
      lpWeb3Helper: ref({}),
      payContractWeb3Helper: ref({}),
      swapRouterV2Web3Helper: ref({}),
      swapRouterV3Web3Helper: ref({}),
      
      showTxid: ref(false),
      txid: ref(""),
      inAddress: ref(""),
      inAddress2: ref(""),
      inAddress3: ref(""),

      zflx: ref("1"),

      bnbPrice: ref(0),

      num: ref(''), // 充值数量
      num2: ref(''), // 充值数量

      userAllowance: ref({
        usdt:0,
        lbd:0,
        lp:0
      }),
      balance: ref({
        bnb:0,
        usdt:0,
        lbd:0,
        lp:0
      })
    }
  },
  created() {
    this.getSelect()
  },
  methods: {
    copy(val) {
      let that = this
      const { toClipboard } = useClipboard()
      try {
        toClipboard(val)
        that.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ['Replicating Success'],
            persistent: true,
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    onClick(index) {
      this.active = index
      this.jine = this.touziSetting.amounts[index]
    },
    tolink(path) {
      this.$router.push(path)
    },
    load() {
      this.us = JSON.parse(this.$utils.getloc("us"))
      if (this.us) {
        this.buyInfo = this.us.lsk > 0 ? '复购' : '认购'
      }
      this.getInfo()
      this.getArticle()
    },
    plus() {
      this.jine = parseFloat(this.jine) + 100
    },
    reduce() {
      if (this.jine > 0) {
        this.jine -= 100
        this.jine = this.jine < 0 ? 0 : this.jine
      }
    },
    getInfo() {
      let _this = this
      _this.$request.post(
        "api/EthContract/Info",
        {},
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data

          _this.contractInfoBNB = data.contractList.find(item => item.id == 5);

          _this.contractInfoUSDT = data.contractList.find(item => item.id == 1);
          _this.usdtWeb3Helper = new Web3Helper(_this.contractInfoUSDT.abi, _this.contractInfoUSDT.address);

          _this.contractInfoLBD = data.contractList.find(item => item.id == 14);
          _this.lbdWeb3Helper = new Web3Helper(_this.contractInfoLBD.abi, _this.contractInfoLBD.address);

          _this.contractInfoLP = data.contractList.find(item => item.id == 15);
          _this.lpWeb3Helper = new Web3Helper(_this.contractInfoLP.abi, _this.contractInfoLP.address);
          
          _this.contractInfoApproveContract = data.contractList.find(item => item.id == 102);

          _this.contractInfoPayContract = data.contractList.find(item => item.id == 103);
          _this.payContractWeb3Helper = new Web3Helper(_this.contractInfoPayContract.abi, _this.contractInfoPayContract.address);

          var swapRouterV2ContractInfo = data.contractList.find(item => item.id == 100)
          _this.swapRouterV2Web3Helper = new Web3Helper(swapRouterV2ContractInfo.abi, swapRouterV2ContractInfo.address);

          _this.contractInfoSwapRouterV3 = data.contractList.find(item => item.id == 101)
          _this.swapRouterV3Web3Helper = new Web3Helper(_this.contractInfoSwapRouterV3.abi, _this.contractInfoSwapRouterV3.address);

          _this.inAddress = data.inAddress;
          _this.inAddress2 = data.inAddress2;
          _this.inAddress3 = data.inAddress3;
          _this.bnbPrice = _this.contractInfoBNB.priceIn

          _this.balanceOf(_this.us.address);
          _this.allowance(_this.us.address)
        }
      )
    },
    allowance(address) {
      let _this = this;

      _this.usdtWeb3Helper.contractAllowance(address, _this.contractInfoSwapRouterV3.address).then((wei) => {
        _this.userAllowance.usdt = _this.usdtWeb3Helper.fromWei(wei, _this.contractInfoUSDT.decimals);
      });
      _this.lbdWeb3Helper.contractAllowance(address, _this.contractInfoApproveContract.address).then((wei) => {
        _this.userAllowance.lbd = _this.lbdWeb3Helper.fromWei(wei, _this.contractInfoLBD.decimals);
      });
      _this.lpWeb3Helper.contractAllowance(address, _this.contractInfoApproveContract.address).then((wei) => {
        _this.userAllowance.lp = _this.lpWeb3Helper.fromWei(wei, _this.contractInfoLP.decimals);
      });
    },
    balanceOf(address) {
      let _this = this;

      _this.usdtWeb3Helper.getBalance(address).then((wei) => {
        _this.balance.bnb = wei;
      });

      _this.usdtWeb3Helper.contractBalanceOf(address).then((wei) => {
        _this.balance.usdt = _this.usdtWeb3Helper.fromWei(wei, _this.contractInfoUSDT.decimals);
      });

      _this.lbdWeb3Helper.contractBalanceOf(address).then((wei) => {
        _this.balance.lbd = _this.lbdWeb3Helper.fromWei(wei, _this.contractInfoLBD.decimals);
      });

      _this.lpWeb3Helper.contractBalanceOf(address).then((wei) => {
        _this.balance.lp = _this.lpWeb3Helper.fromWei(wei, _this.contractInfoLP.decimals);
      });
    },
    approve(lx) {
      let _this = this

      let web3Helper;

      if (lx==1) {
        web3Helper = _this.usdtWeb3Helper;
      } else if(lx==2) {
        web3Helper = _this.lbdWeb3Helper;
      } else if(lx==3) {
        web3Helper = _this.lpWeb3Helper;
      }

      let payWei = web3Helper.web3.utils.toBN('2').pow(web3Helper.web3.utils.toBN('256')).sub(web3Helper.web3.utils.toBN('1'));

      _this.$q.loading.show({
        message: _this.$i18n.t("正在交易中...")
      })

      let approveAddress;
      if (lx == 1) {
        approveAddress = _this.contractInfoSwapRouterV3.address;
      } else {
        approveAddress = _this.contractInfoApproveContract.address;
      }

      web3Helper.contractApprove(_this.us.address, approveAddress, payWei.toString()).then((txid) => {
        _this.$q.loading.hide();
        _this.allowance(_this.us.address)
      }, (err) => {
        _this.$q.loading.hide();
        //过滤4001,4001=拒绝签名
        if (err.code != 4001) {
          _this.$q.dialog({
            title: err.code,
            message: err.message,
          });
        }
      });
    },
    chongzhi() {
      let _this = this
      if (_this.num == "") {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请输入数量"],
            persistent: true,
          }
        });
        return
      }
      if (_this.userAllowance < _this.num) {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["授权金额不足"],
            persistent: true,
          }
        });
        return
      }
      this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: ['确定要充值吗?'],
          persistent: true,
          showCancel: true
        }
      }).onOk(() => {
        let payWei = _this.lbdWeb3Helper.toWei(_this.num, _this.contractInfoLBD.decimals);
        let outWei = 0;

        _this.$q.loading.show({
          message: _this.$i18n.t("正在交易中...")
        })

        _this.payContractWeb3Helper.buy(_this.us.address, _this.inAddress3, 2, payWei.toString(), outWei.toString()).then((txid) => {
          _this.$q.loading.hide();
          _this.txid = txid
          _this.showTxid = true
          _this.balanceOf(_this.us.address)
        }, (err) => {
          _this.$q.loading.hide();
          //过滤4001,4001=拒绝签名
          if (err.code != 4001) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                title: err.code,
                messages: [err.message],
                persistent: true,
              }
            });
          }
        });
      })
    },
    chongzhi2() {
      let _this = this
      if (_this.num2 == "") {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请输入数量"],
            persistent: true,
          }
        });
        return
      }
      this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: ['确定要充值吗?'],
          persistent: true,
          showCancel: true
        }
      }).onOk(() => {
        let payWei = _this.lpWeb3Helper.toWei(_this.num2, _this.contractInfoLP.decimals);

        _this.$q.loading.show({
          message: _this.$i18n.t("正在交易中...")
        })

        _this.lpWeb3Helper.contractTransfer(_this.us.address, _this.inAddress, payWei.toString()).then((txid) => {
          _this.$q.loading.hide();
          _this.txid = txid
          _this.showTxid = true
          _this.balanceOf(_this.us.address)
        }, (err) => {
          _this.$q.loading.hide();
          //过滤4001,4001=拒绝签名
          if (err.code != 4001) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                title: err.code,
                messages: [err.message],
                persistent: true,
              }
            });
          }
        });
      })
    },
    touzi() {
      let _this = this
      if (_this.jine == "") {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请输入数量"],
            persistent: true,
          }
        });
        return
      }
      if (_this.touziSetting.switch != 1) {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["当前无法进行投资,请联系管理员"],
            persistent: true,
          }
        });
        return
      }
      if (parseFloat(_this.touziSetting.amountMin) > parseFloat(_this.jine) || parseFloat(_this.touziSetting.amountMax) < parseFloat(_this.jine)) {
        let msg = ""
        let msgList = ExtractChineseWords(`不在投资范围(${_this.touziSetting.amountMin}~${_this.touziSetting.amountMax})`)
        msgList.forEach(item => {
          msg += _this.$i18n.t(item)
        });
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: [msg],
            persistent: true,
          }
        });
        return
      }
      if (parseFloat(_this.jine) % 100 != 0 || parseFloat(_this.jine) < 100) {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["最低100，且100的倍数起投"],
            persistent: true,
          }
        });
        return
      }
      if (_this.userAllowance.usdt < parseFloat(_this.jine)) {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["授权金额不足，请先授权"],
            persistent: true,
          }
        });
        return
      }
      _this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: [`确定要${_this.buyInfo}吗?`],
          persistent: true,
          showCancel: true
        }
      }).onOk(() => {
        let payWei = _this.usdtWeb3Helper.toWei(_this.jine, _this.contractInfoUSDT.decimals);
        let out = _this.jine - (_this.jine * 15 / 1000);
        let outWei = _this.usdtWeb3Helper.toWei(out, _this.contractInfoUSDT.decimals);

        _this.$q.loading.show({
          message: _this.$i18n.t("正在交易中...")
        })

        //_this.payContractWeb3Helper.buy(_this.us.address, _this.inAddress, 1, payWei.toString(), outWei.toString()).then((txid) => {
        _this.swapRouterV3Web3Helper.exactInputSingle(_this.us.address, "0x55d398326f99059fF775485246999027B3197955", "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d", 100, _this.inAddress, payWei.toString(), outWei.toString()).then((txid) => {
          _this.$q.loading.hide();
          _this.txid = txid
          _this.showTxid = true
          _this.balanceOf(_this.us.address)
        }, (err) => {
          _this.$q.loading.hide();
          //过滤4001,4001=拒绝签名
          if (err.code != 4001) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                title: err.code,
                messages: [err.message],
                persistent: true,
              }
            });
          }
        });
      })
    },
    touzi2() {
      let _this = this
      if (_this.jine == "") {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["请输入数量"],
            persistent: true,
          }
        });
        return
      }
      if (_this.touziSetting.switch != 1) {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["当前无法进行投资,请联系管理员"],
            persistent: true,
          }
        });
        return
      }
      if (parseFloat(_this.touziSetting.amountMin) > parseFloat(_this.jine) || parseFloat(_this.touziSetting.amountMax) < parseFloat(_this.jine)) {
        let msg = ""
        let msgList = ExtractChineseWords(`不在投资范围(${_this.touziSetting.amountMin}~${_this.touziSetting.amountMax})`)
        msgList.forEach(item => {
          msg += _this.$i18n.t(item)
        });
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: [msg],
            persistent: true,
          }
        });
        return
      }
      if (parseFloat(_this.jine) < 98) {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["最低98起投"],
            persistent: true,
          }
        });
        return
      }

      let payBnb = (_this.jine * _this.bnbPrice).toFixed(6);

      _this.$q.dialog({
        component: CustomComponent,
        componentProps: {
          messages: [`确定要${_this.buyInfo}吗?`],
          persistent: true,
          showCancel: true
        }
      }).onOk(() => {

        let payWei = _this.usdtWeb3Helper.web3.utils.toWei(payBnb, "ether");

        _this.$q.loading.show({
          message: _this.$i18n.t("正在交易中...")
        })

        _this.usdtWeb3Helper.transfer(_this.us.address, _this.inAddress2, payWei.toString()).then((txid) => {
          _this.$q.loading.hide();
          _this.txid = txid
          _this.showTxid = true
          _this.getBalance(_this.us.address)
        }, (err) => {
          _this.$q.loading.hide();
          //过滤4001,4001=拒绝签名
          if (err.code != 4001) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                title: err.code,
                messages: [err.message],
                persistent: true,
              }
            });
          }
        });
      })
    },
    getSelect() {
      let _this = this
      _this.$request.post(
        "api/TouziSetting/GetSelect",
        {},
        (res) => {
          let data = res.data.data
          data.amounts = data.amounts.split(",")
          data.shouyiBilis = data.shouyiBilis.split(",")
          _this.touziSetting = data
          _this.onClick(_this.active);
          _this.$forceUpdate()
        }
      )
    },
    getArticle() {
      let _this = this
      _this.$request.post(
        "api/Article/Get",
        {
          select_id: 7,
        },
        (res) => {
          _this.articleData = res.data.data.articlecontent;
        }
      )
    }
  }
}
</script>

<style scoped>
.mintage {
  margin-top: 15px;
  padding: 15px;
  border-radius: 20px;
  background-color: #2f1d51;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-icon {
  width: 25px;
}

.title {
  margin-left: 10px;
  font-size: 18px;
  color: #fff;
}

.consume {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.consume-icon {
  width: 15px;
  height: 15px;
}

.consume-title {
  margin-left: 5px;
  font-size: 13px;
  color: #fff;
}

.form {
  color: #fff;
}

.input-wrap {
  margin-top: 10px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0D0B18;
  border-radius: 12px;
}

.input-label {
  font-size: 15px;
  color: #999;
}

.input-row {
  display: flex;
  align-items: center;
}

.stepper-icon {
  width: 22px;
}

.input {
  margin: 0 10px;
  width: 60px;
}

.mintage-text {
  color: #e96dff;
}

.cell {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
}

.btn {
  margin-top: 20px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  border-radius: 10px;
}

.link {
  color: #e96dff;
  text-decoration: underline;
}

.select {
  color: #fff;
}

.select-list {
  display: flex;
  overflow-x: auto;
  padding: 10px 0;
}

.select-list-item {
  flex-shrink: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 180px;
  height: 240px;
  margin: 0 10px 10px 0;
  padding: 10px 0;
  border-radius: 5px;
  background-size: 100% 100%;
  background-image: url(../../../assets/img//touzi/border.png);
}

.active {
  background-size: 100% 100%;
  background-image: url(../../../assets/img//touzi/active.png);
}

.article {
  color: #fff;
  padding: 20px 0;
}

.text-body1 {
  font-size: 16px;
}

.text-body2 {
  font-size: 14px;
  margin-top: 10px;
}

.input-wrap {
  padding: 0 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #221c35;
}

.q-dialog-plugin {
  max-height: calc(100vh - 108px);
  background: #000;
  color: #fff;
  border: 2px solid #f56ef9;
}

.dialog-title {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em;
  padding: 16px;
}

.btn-row {
  display: flex;
  justify-content: space-between;
}

.btn-row .btn {
  width: 48%;
}
</style>