<template>
    <div class="record">
        <headbar :title="$t('认购')"></headbar>

        <Touzi ref="touziRef" />
    </div>
</template>
  
<script>
import Touzi from "@/views/home/components/Touzi";

export default {
    name: '',
    components: {
        Touzi
    },
    setup() {
        return {
        }
    },
    mounted() {
        this.refresh()
    },
    methods: {
        refresh() {
            this.$refs.touziRef.load()
        },
    }
}
</script>
  
<style scoped>
.record {
    padding: 10px;
    min-height: 100vh;
    background-color: #080535
}
</style>